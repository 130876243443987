﻿* {
    margin: 0;
    padding: 0;
}

html,
body {
    position: relative;
    height: 100%;
}

body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}



a {
    text-decoration: none;
}

img {
    width: 100%;
    height: auto;
    display: block;
}

.header {
    width: calc(100% - 30px);
    z-index: 3;
    position: fixed;
    top: 0;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;

    @media (max-width: 768px) {
        flex-wrap: wrap;

        .logo {
            width: 100%;
            margin-bottom: 15px;

            img {
                display: block;
                max-width: 125px;
            }
        }

        .menu {
            width: 100%;

            a {
                font-size: 15px;
            }
        }
    }


    .logo {
        max-width: 175px;
    }

    .menu {
        a {
            color: #575756;
            font-weight: 600;
            margin-right: 15px;
            font-size: 14px;
            text-transform: uppercase;

            &.active {
                color: #ffc200;
            }

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                color: #ffc200;
            }
        }
    }
}

.home {
    background-image: url(./build/bg.jpg);
    background-size: cover;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        font-weight: 800;
        color: white;
        text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.70);
    }

    @media (max-width:768px) {
        h1 {
            text-align: center;
            font-size: 21px;
            padding: 15px;
        }
    }
}

p {
    line-height: 1.5em;
}

h2 {
    margin-bottom: 15px;
    border-bottom: 2px solid #ffc200;
    display: inline-block;
    font-weight: 300;
}

.container {
    padding-top: 150px;
    color: #575756;
    max-width: 960px;
    margin: auto;
    width: 90%;

    a {
        color: #575756;

        &:hover {
            color: #ffc200;
        }
    }

    .contact {
        margin-top: 50px;
    }

    .services {
        list-style-type: square;

        li {
            width: 100%;
            margin: 10px 0 10px 0;
            border-radius: 10px;
            line-height: 1.5em;
        }
    }

    .clients {
        list-style: none;
        display: flex;
        flex-wrap: wrap;

        li {
            width: 30%;

            &.deactive {
                opacity: .5;

                img {
                    -webkit-filter: grayscale(100%);
                    /* Safari 6.0 - 9.0 */
                    filter: grayscale(100%);
                }
            }
        }
    }
}

.works {
    display: flex;
    flex-wrap: wrap;

    .work {
        width: calc(33.333% - 20px);
        height: 200px;

        margin: 5px;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 5px;
        overflow: hidden;
        background: black;

        &:hover {
            .work-bg {
                opacity: .0;
            }
        }

        .work-bg {
            background-size: cover;
            background-position: center center;
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            transform: scale(1.5);
            opacity: .5;
            transition: all .2s ease-in-out;
        }

        a {
            color: white;
            z-index: 2;
            transition: all .3s ease-in-out;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                transform: scale(1.1);
            }

            h3 {
                font-size: 18px;
            }
        }

        @media (max-width: 768px) {
            width: calc(50% - 20px);
            float: left;
            height: 150px;

           a{
            h3{
                font-size:15px;
            }
           }
        }

        img {
            width: 100%;
            height: auto;

            -webkit-filter: grayscale(100%);
            /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);

            &:hover {
                -webkit-filter: grayscale(0);
                /* Safari 6.0 - 9.0 */
                filter: grayscale(0);
            }
        }

        h3 {
            font-size: 14px;
            margin: 10px 0 10px 0;
        }
    }
}

